/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import MccMncSelector from 'components/MccMncSelector'
import SenderIdsDirectorySelector from 'components/SenderIdsDirectorySelector'
import CustomerAccountSelector from 'components/CustomerAccountSelector'
import VendorAccountSelector from 'components/VendorAccountSelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import BlockDestinationAddressUpsertMutation from './__mutation__/index.blockDestinationAddress.upsert.mutation'
import BlockDestinationAddressReadQuery from './__query__/index.blockDestinationAddress.read.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, type, isCreateOnly, isVendor, onClose, passOn }) => {
  // Const assignment.
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [error, setError] = React.useState('')
  const [mcc, setMcc] = React.useState(void 0)
  const [mnc, setMnc] = React.useState([])
  const [omitDestinationAddress, setOmitDestinationAddress] = React.useState([])
  const [accountType, setCustomerType] = React.useState(type)
  const [selectedAccountId, setSelectedAccountId] = React.useState([])
  const [senderIdDirectoryId, setSenderIdDirectoryId] = React.useState(void 0)
  const [senderIds, setSenderIds] = React.useState([])
  const [MutationBlockDestinationAddressUpsert, MutationBlockDestinationAddressUpsertResponse] = useMutation(BlockDestinationAddressUpsertMutation)
  const _QueryBlockDestinationAddressRead = useQuery(BlockDestinationAddressReadQuery, { 'variables': { 'blockDestinationAddressId': passOn?.blockDestinationAddressId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _accountIds, _mcc, _mnc, _senderIdDirectoryId, _senderIds

    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Variable assignment.
    _accountIds = selectedAccountId
    _mcc = mcc
    _mnc = mnc
    _senderIds = senderIds
    _senderIdDirectoryId = senderIdDirectoryId

    // Only update mnc if it is available.
    if (!_.isEmpty(selectedAccountId)) _accountIds = selectedAccountId?.map(i => i?.includes('(') && i?.includes(')') ? i.split('(')[1].split(')')[0] : i)
    if (mcc && (mcc?.includes('(') || mcc?.includes(')'))) _mcc = mcc.split('(')[1].split(')')[0]
    if (mnc && 0 < mnc?.length) _mnc = _.compact(_.flatten(mnc.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
    if (senderIdDirectoryId && (senderIdDirectoryId?.includes('(') || senderIdDirectoryId?.includes(')'))) _senderIdDirectoryId = senderIdDirectoryId.split('(')[1].split(')')[0]
    if (senderIds && 0 < senderIds?.length) _senderIds = _.compact(_.flatten(senderIds.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

    // Variable assignment.
    const _data = {
      omitDestinationAddress,
      type,
      [isVendor ? 'vendorAccountIds' : 'customerAccountIds']: _accountIds,
      'mcc': _mcc,
      'mnc': _mnc,
      'senderIds': _senderIds,
      'senderIdDirectoryId': _senderIdDirectoryId
    }

    // Schema assignment for joi validation.
    const _JoiSchema = JoiBrowser.object({
      [isVendor ? 'vendorAccountIds' : 'customerAccountIds']: JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'mcc': JoiBrowser.string().allow('').allow(null),
      'mnc': JoiBrowser.array(),
      'omitDestinationAddress': JoiBrowser.array().items(JoiBrowser.string().allow('').allow(null)).allow(null)
    }).options({ 'allowUnknown': true })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_data)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Update data with blockDestinationAddressIds if available.
    if (!isCreateOnly) _data.blockDestinationIds = _.compact([passOn?.blockDestinationAddressId, ...passOn.blockDestinationAddressIds])

    // If isCreateOnly is true then create new BDA else update existing BDA.
    const _MutationBlockDestinationAddress = await MutationBlockDestinationAddressUpsert({ 'variables': _data })

    // If creating or updating BDA caught an exception then report failure.
    if (_MutationBlockDestinationAddress instanceof Error) return _MutationBlockDestinationAddress

    // Report success.
    toast(_MutationBlockDestinationAddress?.data?.BlockDestinationAddressUpsert?.message)

    // Close modal.
    onClose()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update all states if data is available.
    if (!isCreateOnly && !_.isEmpty(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique) && _successFlags?.includes(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.status)) {
      // Update all states.
      setCustomerType(type)
      setSelectedAccountId(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.displayName ? [`${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.displayName} (${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.id})`] : void 0)
      setOmitDestinationAddress(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.omitDestinationAddress)
      setMcc(_.isEmpty(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.Mcc) ? void 0 : `${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.Mcc?.countryName} (${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.Mcc?.mcc})`)
      setMnc(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.Mnc?.map?.(j => `(${j?.mnc}) ${j?.network}`))
      setSenderIdDirectoryId(_.isEmpty(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.SenderIdDirectory) ? void 0 : `${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.SenderIdDirectory?.displayName} (${_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.SenderIdDirectory?.id})`)
      setSenderIds(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressReadUnique?.SenderId?.map?.(j => `${j?.senderId} (${j?.id})`))
    }
  }, [isOpen, passOn, _QueryBlockDestinationAddressRead])

  // Const assignment.
  const _isLoading = MutationBlockDestinationAddressUpsertResponse.loading
  const _isInputDisabled = _isLoading || _QueryBlockDestinationAddressRead?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex w='100%' gap='22px' flexDir='column'>
        <Flex w='100%' gap='22px'>
          <MemoizedSelect
            isRequired={true}
            name='accountType'
            label='Select Account'
            placeholder='e.g. "Account Type"'
            isInvalid={error?.includes('accountType')}
            error={error}
            options={Object.React.App.enums.CONTENT_TRANSLATION_TYPE.enums?.map(i => i.key)}
            data={isCreateOnly ? accountType : type}
            disabled={type || !isCreateOnly}
          />
          {
            isVendor ? (
              <VendorAccountSelector
                isRequired={true}
                disabled={_isInputDisabled}
                name='vendorAccountIds'
                label='Select Account'
                placeholder='e.g. "Select Account"'
                onChange={({ target }) => setSelectedAccountId([target?.value])}
                isInvalid={error?.includes('vendorAccountIds')}
                error={error}
                data={isCreateOnly ? void 0 : _.first(selectedAccountId)}
              />
            ) : (
              <CustomerAccountSelector
                isRequired={true}
                disabled={_isInputDisabled}
                name='customerAccountIds'
                customerType={type}
                label='Select Account'
                placeholder='e.g. "Select Account"'
                onChange={({ target }) => setSelectedAccountId([target?.value])}
                isInvalid={error?.includes('customerAccountIds')}
                error={error}
                data={isCreateOnly ? void 0 : _.first(selectedAccountId)}
              />
            )
          }
        </Flex>
        <MccMncSelector
          disabled={_isInputDisabled}
          mccValue={mcc}
          mncValue={mnc}
          inValidMnc={error?.includes('mnc')}
          inValidMcc={error?.includes('mcc')}
          onChange={i => {
            // Update form data.
            setMcc(i.mcc)
            setMnc(i.mnc)
          }}
        />
        <SenderIdsDirectorySelector
          disabled={_isInputDisabled}
          senderIdDirectoryValue={senderIdDirectoryId}
          senderIdValue={senderIds}
          inValidSenderId={error?.includes('senderId')}
          inValidSenderIdDirectory={error?.includes('senderIdDirectory')}
          onChange={i => {
            // Update form data.
            setSenderIdDirectoryId(i.senderIdDirectory)
            setSenderIds(i.senderId)
          }}
        />
        <MemoizedInput
          isRequired={true}
          disabled={_isInputDisabled}
          name='omitDestinationAddress'
          label='Numbers (Separated by comma ,)'
          placeholder='e.g. "918826668515, +918826668515, 08826668515, 8826668515"'
          onChange={({ target }) => setOmitDestinationAddress(target?.value)}
          isInvalid={error?.includes('phoneNumbers')}
          error={error}
          data={omitDestinationAddress}
          isMultiple={true}
          separators={[',', 'Enter', 'Tab']}
        />
      </Flex>
      <SubmitButton
        onSubmit={_SubmitForm}
        defaultText='Upsert Block Number'
        disabled={MutationBlockDestinationAddressUpsertResponse.loading}
        isLoading={MutationBlockDestinationAddressUpsertResponse.loading} />
    </form >
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool,
  'isVendor': PropTypes.bool,
  'type': PropTypes.string,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
