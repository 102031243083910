/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * OBJECTS
 */
const Index = ({ onChange, ...props }) => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Return component.
  return (
    <MemoizedSelect
      w='100%'
      position='relative'
      zIndex={10000}
      placeholder='e.g. "Special Vendor"'
      onChange={onChange}
      value={props.value ?? props.data}
      options={_.compact(_QueryVendorAccountRead?.data?.VendorAccountRead?.map(({ id, displayName }) => id && displayName ? `${displayName} (${id})` : void 0))}
      {...props}
    />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onChange': PropTypes.func.isRequired
}


/*
 * EXPORT
 */
export default Index
