/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import CustomerAccountSelector from 'components/CustomerAccountSelector'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'


/*
 * GRAPHS
 */
import CustomerAccountMarginReportQuery from './__query__/index.customerAccount.marginReport.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [selectedCustomerAccount, setSelectedCustomerAccount] = React.useState(void 0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const _QueryCustomerAccountMarginReport = useQuery(CustomerAccountMarginReportQuery, {
    'variables': { 'customerAccountId': selectedCustomerAccount, 'startDate': dateRange.selection.startDate, 'endDate': dateRange.selection.endDate },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  const _data = Object.entries(_QueryCustomerAccountMarginReport?.data?.CustomerAccountMarginReport?._report?.[selectedCustomerAccount] ?? {})

  // Return component.
  return (
    <>
      <Flex className='reportsCustomer base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justifyContent='space-between' alignItems='center'>
            <Flex flex={0.6} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date'}.</Text>
              <DateRangePicker
                startDate={dateRange?.selection?.startDate}
                endDate={dateRange?.selection?.endDate}
                w='100%'
                fontSize='13px'
                height='max-content'
                gap='6px'
                setSelectDate={setDateRange}
              />
            </Flex>
            <Flex flexDir='column' gap='12px' w='240px' justify='end' alignItems='end'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'>{_isCurrentViewMobile ? 'Filter Accounts' : 'Select customer account'}.<Text transform='scale(-1, 1)'><HiArrowUturnDown /></Text></Text>
              <CustomerAccountSelector type='retail' accountId={passOn?.accountId} onChange={e => setSelectedCustomerAccount(e.target.value?.includes('(') && e.target.value?.includes(')') ? e.target.value?.split('(')[1]?.split(')')[0] : void 0)} />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='md'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={headerStyle}
                  position='sticky'
                  top={0}
                  zIndex='docked'>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Total Sms
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Revenue ( in % )
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Cost ( in % )
                  </Td>
                  <Td
                    style={rowStyle}>
                    Margin ( in % )
                  </Td>
                </Tr>
              </Thead>
              {_QueryCustomerAccountMarginReport.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === _.compact(_data)?.length || (0 < _.compact(_data)?.length && _.every(_.pluck(_.compact(_data), 'status'), j => 'NO_SMS_FOUND' === j || 'CUSTOMERS_NOT_FOUND' === j)) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  title='Kindly Choose Customer Account to see peformance.'
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_.compact(_data)?.map(
                    (item, __index) => (
                      <Tr key={String.random(8)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          width='100%'
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[0] ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[1]?.totalsmscount ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {parseFloat(item?.[1]?.revenue ?? 0)?.toFixed?.(2) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {parseFloat(item?.[1]?.cost ?? 0)?.toFixed?.(2) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}>
                          {parseFloat(item?.[1]?.margin ?? 0)?.toFixed?.(2) ?? '-'}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCustomerAccountMarginReport?.data?.CustomerAccountMarginReport, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
