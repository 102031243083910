/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiUsers } from 'react-icons/hi2' // Npm: React icons.
import { Flex, Spinner, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * COMPONENTS
 */
import DateRangePicker from 'components/DateRangePicker'


/*
 * GRAPHS
 */
import CustomerCountQuery from './__query__/index.customer.count.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _QueryCustomerCount = useQuery(CustomerCountQuery, {
    'variables': {
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })

  // Return component.
  return (
    <Flex
      my='auto'
      w={{ 'base': '98%', 'md': '100%', 'lg': '100%' }}
      borderRadius='20px'
      bg='white'
      h='140px'
      p='15px'
      gap='12px'
      flexDir='column'
      color='#1B2559'
      fontSize={['clamp(13px, 1.5vw, 16px)']}
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <DateRangePicker
        startDate={dateRange?.selection?.startDate}
        endDate={dateRange?.selection?.endDate}
        w='100%'
        fontSize='13px'
        height='max-content'
        gap='6px'
        setSelectDate={setDateRange}
        isLoading={_QueryCustomerCount?.loading}
        type='masterview' />
      {_QueryCustomerCount?.loading ? (
        <Spinner mx='auto' my='auto' color='#c1a9f4' />
      ) : (
        <>
          <Flex w='100%' justify='space-between'>
            <Flex
              my='auto'
              display='flex'
              flexDir='column'>
              <Text
                lineHeight='100%'
                color='gray.500'
                fontWeight='600'
                fontSize='13px'
                mb='8px'>
                New Customers
              </Text>
              <Text
                fontSize={['clamp(15px, 2vw, 25px)']}
                fontWeight='900'
                color='#1B2559'>
                {`${('COUNT_SUCCESSFUL' === _QueryCustomerCount?.data?.CustomerCount?.status ? _QueryCustomerCount?.data?.CustomerCount?.count : 0)}`}
              </Text>
            </Flex>
            <Flex
              w='60px'
              h='60px'
              bg='#F1F5FF'
              borderRadius='10px'
              align='center'
              justify='center'>
              <HiUsers color='#4308FF' size={25} />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index
